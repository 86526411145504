import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import csrfFetch from '../../app/fetch';
import { Mealplan } from './mealplanTypes';
import { LoadingStatus } from '../meals/mealsSlice';
import Cookies from 'js-cookie';

export type MealplanState = {
  plan: Mealplan[];
  status: LoadingStatus;
};

const initialState: MealplanState = {
  plan: [],
  status: LoadingStatus.loading,
};

export const getMealplan = createAsyncThunk(
  'mealplan/getMealplan',
  async () => {
    const plan = await csrfFetch('/api/mealplan', {
      headers: {
        Authorization: `bearer ${Cookies.get('refreshToken')}`,
      },
    });
    console.log('plan', plan);
    return plan;
  },
);

export const updateMealplan = createAsyncThunk(
  'mealplan/updateMealplan',
  async (plan: { date: string; mealId: number; add: boolean }) => {
    const updatedPlan = await csrfFetch('/api/mealplan', {
      method: 'PATCH',
      headers: {
        Authorization: `bearer ${Cookies.get('refreshToken')}`,
      },
      body: JSON.stringify(plan),
    });
    return updatedPlan;
  },
);

export const mealplanSlice = createSlice({
  name: 'mealplan',
  initialState,

  reducers: {
    setMealplan: (state, action: PayloadAction<MealplanState>) => {
      state = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getMealplan.pending, (state) => {
        state.status = LoadingStatus.loading;
      })
      .addCase(
        getMealplan.fulfilled,
        (state, action: PayloadAction<Mealplan[]>) => {
          state.plan = action.payload;
          state.status = LoadingStatus.success;
        },
      )
      .addCase(getMealplan.rejected, (state) => {
        state.status = LoadingStatus.failed;
      })
      .addCase(updateMealplan.pending, (state) => {
        state.status = LoadingStatus.loading;
      })
      .addCase(
        updateMealplan.fulfilled,
        (state, action: PayloadAction<Mealplan[]>) => {
          state.plan = action.payload;
          state.status = LoadingStatus.success;
        },
      )
      .addCase(updateMealplan.rejected, (state) => {
        state.status = LoadingStatus.failed;
      });
  },
});

export const { setMealplan } = mealplanSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMealplan = (state: RootState) => state.mealplan.plan;
export const selectMealplanByDate = (date: Date) => (state: RootState) =>
  state.mealplan.plan.filter((p) => p.date === date);
export const selectStatus = (state: RootState) => state.mealplan.status;

export default mealplanSlice.reducer;
