import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, store } from '../../app/store';
import csrfFetch from '../../app/fetch';
import * as MealTypes from './mealTypes';

export enum LoadingStatus {
  loading,
  failed,
  success,
}

export type MealsState = {
  meals: MealTypes.MealDto[];
  fullMeals: MealTypes.Meal[];
  status: LoadingStatus;
};

const initialState: MealsState = {
  meals: [],
  fullMeals: [],
  status: LoadingStatus.loading,
};

export const getAllMeals = createAsyncThunk(
  'meals/getAllMeals',
  async (query: MealTypes.SearchMealDto) => {
    query.fullDetails = query.fullDetails || '0';
    const meals = await csrfFetch(
      `/api/meals?${new URLSearchParams(query).toString()}`,
    );
    console.log(meals);
    return meals;
  },
);

export const getFullMeals = createAsyncThunk(
  'meals/getFullMeals',
  async (ids: number[]) => {
    const storeMeals = store.getState().meals.fullMeals;
    const missingMeals = ids.filter(
      (id) => !storeMeals.find((meal) => meal.id === id),
    );
    const meals = await csrfFetch(
      `/api/meals?${new URLSearchParams({ ids: missingMeals.join(','), fullDetails: '1' })}`,
    );
    return meals;
  },
);

export const getMealById = createAsyncThunk(
  'meals/getMealById',
  async (id: number) => {
    const storeMeal = store
      .getState()
      .meals.fullMeals.find((meal) => meal.id === +id);
    if (storeMeal) {
      return storeMeal;
    }
    const meal = await csrfFetch(`/api/meals/${id}`);
    return meal;
  },
);

export const mealsSlice = createSlice({
  name: 'meals',
  initialState,

  reducers: {
    setMeals: (state, action: PayloadAction<MealsState>) => {
      state = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAllMeals.pending, (state) => {
        state.status = LoadingStatus.loading;
      })
      .addCase(getAllMeals.fulfilled, (state, action: PayloadAction<any>) => {
        // action.payload.meals.forEach((meal: MealTypes.Meal) => {
        //   const existingMeal = state.fullMeals.find((m) => m.id === meal.id);
        //   if (!existingMeal) {
        //     state.fullMeals.push(meal);
        //   }
        // });
        state.fullMeals = action.payload.meals;
        state.meals = action.payload.meals;
        state.status = LoadingStatus.success;
      })
      .addCase(getAllMeals.rejected, (state) => {
        state.status = LoadingStatus.failed;
      })
      .addCase(getMealById.pending, (state) => {
        state.status = LoadingStatus.loading;
      })
      .addCase(
        getMealById.fulfilled,
        (state, action: PayloadAction<MealTypes.Meal>) => {
          const meal = state.fullMeals.find((m) => m.id === action.payload.id);
          if (!meal) {
            state.fullMeals.push(action.payload);
          }
          state.status = LoadingStatus.success;
        },
      )
      .addCase(getMealById.rejected, (state) => {
        state.status = LoadingStatus.failed;
      })
      .addCase(getFullMeals.pending, (state) => {
        state.status = LoadingStatus.loading;
      })
      .addCase(getFullMeals.fulfilled, (state, action: PayloadAction<any>) => {
        action.payload.meals.forEach((meal: MealTypes.Meal) => {
          const existingMeal = state.fullMeals.find((m) => m.id === meal.id);
          if (!existingMeal) {
            state.fullMeals.push(meal);
          }
        });
        state.status = LoadingStatus.success;
      })
      .addCase(getFullMeals.rejected, (state) => {
        state.status = LoadingStatus.failed;
      });
  },
});

export const { setMeals } = mealsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMeals = (state: RootState) => state.meals.meals;
export const selectFullMeals = (state: RootState) => state.meals.fullMeals;
export const selectMealsStatus = (state: RootState) => state.meals.status;

export default mealsSlice.reducer;
