import { useAppDispatch } from '../../app/hooks';
import { setActiveFilter } from './searchSlice';
import { FilterOptions } from './searchTypes';

export default function ActiveFilter({ filter }: { filter: string }) {
  const dispatch = useAppDispatch();
  const removeFilter = () => {
    dispatch(
      setActiveFilter({
        filter: filter as keyof FilterOptions,
        checked: false,
      }),
    );
  };

  return (
    <span
      key={filter}
      className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
    >
      <span>{filter}</span>
      <button
        type="button"
        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
        onClick={removeFilter}
      >
        <span className="sr-only">Remove filter for {filter}</span>
        <svg
          className="h-2 w-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
}
