export const steps = [
  {
    title: 'Email',
    path: '/signup',
  },
  {
    title: 'Password',
    path: '/signup/step-two',
  },
  {
    title: 'Name',
    path: '/signup/step-three',
  },
  {
    title: 'Terms & Conditions',
    path: '/signup/step-four',
  },
];
