import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MagnifyingGlassIcon from '../../components/shared/Icons/MagnifyingGlassIcon';
import { selectName, setName } from '../search/searchSlice';
import { useNavigate } from 'react-router-dom';

export default function Search() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const name = useAppSelector(selectName);
  const [search, setSearch] = React.useState(name);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setName(search));
    navigate('/app/meals');
  };

  return (
    <div className="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0">
      <div className="w-full sm:max-w-xs">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon />
          </div>
          <form onSubmit={handleSubmit}>
            <input
              id="search"
              name="search"
              className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              placeholder="Search"
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </div>
      </div>
    </div>
  );
}
