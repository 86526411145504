export default function HamburgerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="30"
      viewBox="0 0 40 30"
    >
      <rect width="40" height="5" style={{ fill: 'black' }} />
      <rect y="12.5" width="40" height="5" style={{ fill: 'black' }} />
      <rect y="25" width="40" height="5" style={{ fill: 'black' }} />
    </svg>
  );
}
