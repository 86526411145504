export const faqs = [
  [
    {
      question: 'Does the meal planner app handle dietary restrictions?',
      answer:
        'Yes, our app allows you to customize meal plans based on various dietary restrictions, including vegetarian, vegan, gluten-free, and keto diets.',
    },
    {
      question: 'Can I pay for my subscription via purchase order?',
      answer:
        'Yes, we accept payment through purchase orders as well as other common payment methods.',
    },
    {
      question: 'How do I apply for a job at the meal planner app company?',
      answer:
        "Please visit our careers page to view open positions and submit your application online. We're always looking for talented individuals to join our team!",
    },
  ],
  [
    {
      question: 'What if I have allergies or food intolerances?',
      answer:
        'Our app allows you to exclude specific ingredients to accommodate allergies and food intolerances, ensuring your meal plans are safe and enjoyable.',
    },
    {
      question: 'Why choose this meal planner app over others?',
      answer:
        'Our meal planner app is designed with user-friendly interfaces and provides unique features like smart grocery lists, nutritional tracking, and customizable meal suggestions that adapt to your preferences and goals.',
    },
    {
      question: 'Is the meal planner app name trademarked?',
      answer:
        'Yes, our app name is officially registered and trademarked to ensure brand identity and legal protection.',
    },
  ],
  [
    {
      question:
        'How do you ensure the nutritional accuracy of your meal plans?',
      answer:
        'We work with certified nutritionists to design our meal plans and continuously update our database with the latest nutritional information.',
    },
    {
      question: 'Can I expect new features in the future?',
      answer:
        "Absolutely! We're constantly working on new features and improvements based on user feedback and the latest trends in nutritional science.",
    },
    {
      question: 'I forgot my password, how can I reset it?',
      answer:
        'You can reset your password by clicking on the "Forgot Password" link on the login page. Instructions for resetting your password will be sent to your registered email address.',
    },
  ],
];
