export const features = [
  {
    title: 'Custom Meal Plans',
    description:
      "Generate personalized meal plans based on your dietary preferences and goals. Perfect for those who want to eat healthily but aren't sure where to start.",
    image: '',
  },
  {
    title: 'Grocery List Integration',
    description:
      "Automatically convert your meal plans into a shopping list, making grocery runs more efficient. Just choose your meals, and we'll handle the list.",
    image: '',
  },
  {
    title: 'Nutritional Tracking',
    description:
      'Keep an eye on your nutritional intake without the hassle. Our app breaks down the macros and calories for you, so you can focus on enjoying your meals.',
    image: '',
  },
  {
    title: 'Recipe Discovery',
    description:
      'Explore a vast library of recipes to find new favorites that fit your lifestyle. Filter by cuisine, dietary restrictions, or cooking time to find your next delicious meal.',
    image: '',
  },
];
