export const testimonials = [
  [
    {
      content:
        "Since I started using the meal planning app, I've noticed a huge change in my energy levels and overall health. The personalized meal plans are easy to follow, and I love the variety of recipes provided. It's like having a nutritionist in my pocket!",
      author: {
        name: 'Emily, 34',
        role: '',
        image: '',
      },
    },
    {
      content:
        "This app has been a game-changer for my busy lifestyle. I can plan meals for the week in just a few minutes, and the grocery list feature saves so much time. It's helped me eat healthier without the stress.",
      author: {
        name: 'Mark, 45',
        role: '',
        image: '',
      },
    },
  ],
  [
    {
      content:
        'I was always intimidated by meal planning, but this app makes it so simple and fun. The interactive features and community support have made me look forward to cooking again. Highly recommend!',
      author: {
        name: 'Sarah, 29',
        role: '',
        image: '',
      },
    },
    {
      content:
        "As someone with specific dietary needs, I've struggled to find recipes that work for me. This app has customizable options that have made it easy to stick to my dietary restrictions. I feel supported and understood.",
      author: {
        name: 'Alex, 38',
        role: '',
        image: '',
      },
    },
  ],
  [
    {
      content:
        "I've tried many meal planning apps, and this one stands out because of its accuracy and ease of use. The nutrition tracking helps me keep on top of my health goals. I've already recommended it to all my friends!",
      author: {
        name: 'Jenna, 27',
        role: '',
        image: '',
      },
    },
    {
      content:
        'What I love about this app is how it adapts to my preferences over time. The AI suggestions for meals each week are spot on, and cooking has become less of a chore and more of a joy.',
      author: {
        name: 'Carlos, 41',
        role: '',
        image: '',
      },
    },
  ],
];
