import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import csrfFetch from '../../app/fetch';
import { Category } from './categoryTypes';
import { LoadingStatus } from '../meals/mealsSlice';

export type CategoryState = {
  categories: Category[];
  diets: Category[];
  status: LoadingStatus;
};

const initialState: CategoryState = {
  categories: [],
  diets: [],
  status: LoadingStatus.loading,
};

export const getAllCategories = createAsyncThunk(
  'category/getAllCategories',
  async () => {
    const categories = await csrfFetch('/api/categories');
    return categories;
  },
);

export const categorySlice = createSlice({
  name: 'category',
  initialState,

  reducers: {
    setCategories: (state, action: PayloadAction<CategoryState>) => {
      state = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategories.pending, (state) => {
        state.status = LoadingStatus.loading;
      })
      .addCase(
        getAllCategories.fulfilled,
        (
          state,
          action: PayloadAction<{ categories: Category[]; diets: Category[] }>,
        ) => {
          state.categories = action.payload.categories;
          state.diets = action.payload.diets;
          state.status = LoadingStatus.success;
        },
      )
      .addCase(getAllCategories.rejected, (state) => {
        state.status = LoadingStatus.failed;
      });
  },
});

export const { setCategories } = categorySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCategories = (state: RootState) => state.category.categories;
export const selectDiets = (state: RootState) => state.category.diets;

export default categorySlice.reducer;
