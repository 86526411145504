import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import csrfFetch from '../../app/fetch';
import { redirect } from 'react-router-dom';

export enum Status {
  loggedOut,
  loading,
  loggedIn,
  failed,
}

export interface UserState {
  id: number;
  name: string;
  email: string;
  avatar: string;
  favoriteMeals: number[];
  friends: number[];
  status: Status;
}

const initialState: UserState = {
  id: 0,
  name: '',
  email: '',
  avatar: '',
  favoriteMeals: [],
  friends: [],
  status: Status.loggedOut,
};

export const signup = createAsyncThunk(
  'user/signup',
  async (user: { email: string; password: string; name: string }) => {
    const data = await csrfFetch('/api/users', {
      method: 'POST',
      body: JSON.stringify({ ...user }),
    });

    if (data.accessToken && data.refreshToken) {
      redirect('/app');
      return data;
    }

    return data;
  },
);

export const signin = createAsyncThunk(
  'user/signin',
  async (user: { username: string; password: string }) => {
    const data = await csrfFetch('/api/auth', {
      method: 'POST',
      body: JSON.stringify({ ...user }),
    });

    if (data.statusCode && data.statusCode === 401) {
      throw new Error('Invalid email or password');
    }

    return data;
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state = action.payload;
    },
    removeUser: (state) => {
      state = initialState;
    },
    addFavoriteMeal: (state, action: PayloadAction<number>) => {
      state.favoriteMeals.push(action.payload);
    },
    removeFavoriteMeal: (state, action: PayloadAction<number>) => {
      state.favoriteMeals = state.favoriteMeals.filter(
        (meal) => meal !== action.payload,
      );
    },
    addFriend: (state, action: PayloadAction<number>) => {
      state.friends.push(action.payload);
    },
    removeFriend: (state, action: PayloadAction<number>) => {
      state.friends = state.friends.filter(
        (friend) => friend !== action.payload,
      );
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(signup.fulfilled, (state, action: PayloadAction<UserState>) => {
        state.status = Status.loggedIn;
      })
      .addCase(signup.rejected, (state) => {
        state.status = Status.failed;
      })
      .addCase(signin.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(signin.fulfilled, (state, action: PayloadAction<UserState>) => {
        state.status = Status.loggedIn;
      })
      .addCase(signin.rejected, (state) => {
        state.status = Status.failed;
      });
  },
});

export const {
  setUser,
  removeUser,
  addFavoriteMeal,
  removeFavoriteMeal,
  addFriend,
  removeFriend,
} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: RootState) => state.user;
export const selectStatus = (state: RootState) => state.user.status;

export default userSlice.reducer;
